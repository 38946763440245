import React, {useEffect, useState} from 'react'
import '../styles/Header.css'
import { useLocation } from 'react-router-dom'
import axios from 'axios'
import { url } from '../URL'
function Header() {
  const params = useLocation()
  const [section, setSection] = useState('')
  const [cate, setCate] = useState('')
  const pathObject = {
    patients:"المراجعين",
    employees:"الموظفين",
    services:"الفئات والخدمات",
    system:"النظام",
    expenses:"المصروفات",
    add:"أضافة",
    birthdays:"أعياد الميلاد",
    contact:"تواصل",
    products:"المواد",
    salaries:"الرواتب",
    materials:"المواد",
    cosmetics:"مواد الكوزمتكس",
    storage:"مواد المخزن",
    sessions:"الجلسات",
    "add-category":"أضافة فئة",
    "add-service":"أضافة خدمة",
    "logs":"النشاط",
    "info":"المعلومات",
    debt:"الديون",
    receipts:"الوصولات",
    preparers:"المجهزين",
    purchases:"المشتريات",
    devices:"الأجهزة",
    'financial-fund':"الصندوق المالي",
    "add-financial-fund":"أضافة صندوق مالي",
    "most-paid":"الأكثر دفعا",
    "settings":"الأعدادات",
    "visits":"الزيارات",
  }
  function configurePath(path){
    const array = path.split('/').filter(d => d !== '')
    const section = array[0]
    const route = array[1]
    const sectionStr = pathObject[section]
    const pathStr = pathObject[route]
    setSection(sectionStr)
    setCate(pathStr)
  }
  const [isLoading, setIsLoading] = useState(true)
  const [data, setData] = useState({name:"", gender:null})
  async function getName(){
      await axios.get(`${url}/get-name`, {withCredentials:true})
                .then(res => {
                  setData(res.data.data)
                  setIsLoading(false)
                })
                .catch(err => {
                  setData({name:"خطأ", gender:null})
                  console.log(err)
                  setIsLoading(false)
                })
  }
  useEffect(() => {
    configurePath(params.pathname)
    if(isLoading){
      getName()
    }
  })
  return (
    <header>
      <div className='container'>
        <div className='account'>
          <div className='image'>
            <img src={data.gender === 'ذكر' ? require('../Assets/Images/male.webp') : require('../Assets/Images/female.webp')} alt='profilepicture' loading='lazy' />
          </div>
          <div>
            <h3>{data.name}</h3>
          </div>
        </div>
        <div className='path'>
          <h3>
              <span>{section} /</span> {cate}
          </h3>
        </div>
      </div>
    </header>
  )
}

export default Header