import React, {useState, useEffect, useRef} from 'react'
import axios from 'axios'
import LoadingIcon from './LoadingIcon'
import { url } from '../URL'
function AddStorageMaterials() {
    
  const inputRef = useRef(null)
  const [isLoading, setIsLoading] = useState(false)
  const [msg, setMsg] = useState('')
  const [data, setData] = useState({
    name:"",
    quantity:0,
    purchasingPrice:0,
    sellingPrice:0,
    unit:"",
    preparerName:"",
    preparer:"",
    expire:"",
    debt:0,
    debtType:""
  })
  useEffect(() => {
    setData((d) => {
      return {...d, category:""}
    })
  }, [data.service])

  const [preparers, setPreparers] = useState([])

  async function loadData(){
    axios.get(`${url}/preparers`, {withCredentials:true})
        .then(res => {
          setPreparers(res.data.data)
        })
        .catch(err => {
          console.log(err)
        })
  }
  useEffect(() => {
    loadData()
  }, [])
  const [dropdowns, setDropdowns] = useState({
    unit:false,
    preparer:false
  })
  function openDropdown(dropdown){
    setDropdowns({
      unit: dropdown === 'unit' ? true : false,
      preparer: dropdown === 'preparer' ? true : false,
      debts: dropdown === 'debts' ? true : false
    })
  }
  useEffect(() => {
    inputRef.current.focus()
  }, [])
  const debts = ["آجل","نقد"]


  async function addStorageProduct(d){
    setIsLoading(true)
    await axios.post(`${url}/storage/add`, {
      data:d
    }, {withCredentials:true})
    .then(res => {
      setMsg(res.data.message)
      setData({
        name:"",
        quantity:0,
        purchasingPrice:0,
        sellingPrice:0,
        unit:"",
        expire:"",
        purchaseDate:""
      })
      setIsLoading(false)
    })
    .catch(err => {
      setMsg(err.response.data.message)
      setIsLoading(false)
    })
  }
  return (
<main className='page-container form-page'>
      <div className='container'>
        <section className='head-section'>
          <h1 className='title'>أضافة مواد</h1>
        </section>
        <section className='form'>
          <form>
            <div className='row' style={{display:'grid', gridTemplateColumns:"repeat(4, 1fr)", gap:".5rem"}}>
              <div className='field-container'>
                <input type='text' ref={inputRef} name='name' id='name' 
                className={data.name === '' ? 'field' : 'active field'} 
                value={data.name} 
                onChange={e => setData({...data, name:e.target.value})} 
                />
                <label className={data.name === '' ? '' : "active"}>أسم المنتج</label>
              </div>
              <div className='field-container'>
                <input type='date' name='name' id='name' 
                className={data.purchaseDate === '' ? 'field' : 'active field'} 
                value={data.purchaseDate} 
                onChange={e => setData({...data, purchaseDate:e.target.value})} 
                />
                <label className={data.purchaseDate === '' ? '' : "active"}>تاريخ الشراء</label>
              </div>
              <div className='field-container'>
                <input type='date' name='name' id='name' 
                className={data.expire === '' ? 'field' : 'active field'} 
                value={data.expire} 
                onChange={e => setData({...data, expire:e.target.value})} 
                />
                <label className={data.expire === '' ? '' : "active"}>تاريخ الأنتهاء</label>
              </div>
              <div className={data.preparerName === '' ? 'field-container dropdown-container' : 'field-container dropdown-container active'}>
                <div className='preparer'  onClick={() => {
                  openDropdown('preparer')
                }}
              >{data.preparerName || "المجهز"}</div>
                <div  className={dropdowns.preparer ? 'dropdown active' : 'dropdown'}>
                  <ul>
                    {
                      preparers.map((item, index) => {
                        return (
                          <li onClick={() => {
                            setData({...data, preparerName:item.name, preparer:item._id})
                            openDropdown('')
                            }} key={index}>{item.name}</li>
                        )
                      })
                    }
                  </ul>
                </div>
              </div>
            </div>
            <div className='row storage-row cosmeticsPricesRow'>
              <div className={data.unit === '' ? 'field-container dropdown-container' : 'field-container dropdown-container active'}>
                <div className='unit'  onClick={() => {
                  openDropdown('unit')
                }}
              >{data.unit || "الوحدة"}</div>
                <div  className={dropdowns.unit ? 'dropdown active' : 'dropdown'}>
                  <ul>
                    <li onClick={() => {
                      setData({...data, unit:"قطعة"})
                      openDropdown('')
                      }}>قطعة</li>
                    <li onClick={() => {
                      setData({...data, unit:"كت"})
                      openDropdown('')
                      }}>كت</li>
                    <li onClick={() => {
                      setData({...data, unit:"فيال"})
                      openDropdown('')
                      }}>فيال</li>
                    <li onClick={() => {
                      setData({...data, unit:"سي سي"})
                      openDropdown('')
                      }}>سي سي</li>
                  </ul>
                </div>
              </div>
              <div className='field-container'>
                <input type='number' min='0' name='quantity' id='quantity' 
                    className={data.quantity === 0 ? 'field'  : 'active field' }
                    value={data.quantity} 
                    onChange={e => setData({...data, quantity:e.target.value})}  />
                <label className={data.quantity === '' ? '' : "active"}>الكمية</label>
              </div>
              <div className='field-container'>
                <input type='number' min='0' step='1000' name='purchasingPrice' id='purchasingPrice' 
                    className={data.purchasingPrice === 0 ? 'field'  : 'active field' }
                    value={data.purchasingPrice} 
                    onChange={e => setData({...data, purchasingPrice:e.target.value})}  />
                <label className={data.purchasingPrice === '' ? '' : "active"}>سعر الشراء</label>
              </div>
              <div className='field-container'>
                <input type='number' min='0' step='1000' name='sellingPrice' id='sellingPrice' 
                    className={data.sellingPrice === 0 ? 'field'  : 'active field' }
                    value={data.sellingPrice} 
                    onChange={e => setData({...data, sellingPrice:e.target.value})}  />
                <label className={data.sellingPrice === '' ? '' : "active"}>سعر البيع</label>
              </div>
            </div>
            <div className='row' style={{display:'grid', gridTemplateColumns:"repeat(2, 1fr)", gap:".5rem"}}>
              <div className='field-container'>
                <input type='number' min='0' step='1000' name='sellingPrice' id='sellingPrice' 
                    className={data.debt === 0 ? 'field'  : 'active field' }
                    value={data.debt} 
                    onChange={e => setData({...data, debt:e.target.value})}  />
                <label className={data.debt === '' ? '' : "active"}>المبلغ الكلي</label>
              </div>
              <div className={data.debt === '' ? 'field-container dropdown-container' : 'field-container dropdown-container active'}  style={data.debt === 0 ? {opacity:0.5, pointerEvents:"none"} : {}}>
                <div className='debts'  onClick={() => {
                  openDropdown('debts')
                }}
              >{data.debtType || "نوع الشراء"}</div>
                <div  className={dropdowns.debts ? 'dropdown active' : 'dropdown'}>
                  <ul>
                    {
                      debts.map((item, index) => {
                        return (
                          <li onClick={() => {
                            setData({...data, debtType:item})
                            openDropdown('')
                            }} key={index}>{item}</li>
                        )
                      })
                    }
                  </ul>
                </div>
              </div>
            </div>
            <div className='row'>
              <button onClick={(e) => {
                e.preventDefault()
                addStorageProduct(data)
              }}>
                <LoadingIcon active={isLoading} />
                <div>
                أضافة
                </div>
              </button>
            </div>
          </form>
          <p style={{textAlign:"center", color:'var(--primary)', marginTop:"1rem", fontSize:"var(--paragraph)"}}>{msg}</p>
        </section>
      </div>
    </main>
  )
}

export default AddStorageMaterials