import React, {useState, useEffect, useRef} from 'react'
import axios from 'axios'
import {url} from '../URL'
import LoadingIcon from './LoadingIcon'
function AddCosmeticsMaterials() {
    
  const inputRef = useRef(null)
  const [msg, setMsg] = useState('')
  const [isLoading, setIsLoading]= useState(false)
  const [isPrepareresLoading,setIsPreparersLoading] = useState(true)
  const [preparers, setPreparers] = useState([])
  const [data, setData] = useState({
    name:"",
    quantity:0,
    purchasingPrice:0,
    sellingPrice:0,
    preparer:"",
    preparerName:"",
    purchaseDate:"",
    expire:"",
    debt:0,
    debtType:"نقد"
  })
  useEffect(() => {
    setData((d) => {
      return {...d, category:""}
    })
  }, [data.service])

  useEffect(() => {
    inputRef.current.focus()
  }, [])


  const [dropdowns, setDropdowns] = useState({
    preparers:false 
  })

  function openDropdown(dropdown){
    setDropdowns({
      preparers:dropdown === 'preparers' ? true : false,
      debts:dropdown === 'debts' ? true : false,
    })
  }

  const debts = ["آجل","نقد"]
  async function loadData(){
    await axios.get(`${url}/preparers`, {withCredentials:false})
            .then(res => {
              setPreparers(res.data.data)
              setIsPreparersLoading(false)
            })
            .catch(err => {
              console.log(err)
              setIsPreparersLoading(false)
            })
  }
  useEffect(() => {
    if(isPrepareresLoading){
      loadData()
    }
  })
  useEffect(() => {
    setData((d) => {
      return {...d, debt:data.quantity * data.purchasingPrice}
    })
  }, [data.quantity, data.purchasingPrice])
  async function addProduct(productData){
    setIsLoading(true)
    await axios.post(`${url}/cosmetics/add`, {data:productData}, {withCredentials:true})
    .then(res => {
              setMsg(res.data.message)
              setIsLoading(false)
              setData({
                name:"",
                quantity:0,
                purchasingPrice:0,
                sellingPrice:0,
                purchaseDate:"",
                expire:""
              })
            })
            .catch(err => {
              console.log(err)
              setMsg(err.response.data.message)
              setIsLoading(false)
              })
  }


  return (
<main className='page-container form-page'>
      <div className='container'>
        <section className='head-section'>
          <h1 className='title'>أضافة مواد</h1>
        </section>
        <section className='form'>
          <form>
            <div className='row' style={{display:'grid', gridTemplateColumns:"repeat(4, 1fr)", gap:".5rem"}}>
              <div className='field-container'>
                <input type='text' ref={inputRef} name='name' id='name' 
                className={data.name === '' ? 'field' : 'active field'} 
                value={data.name} 
                onChange={e => setData({...data, name:e.target.value})} 
                />
                <label className={data.name === '' ? '' : "active"}>أسم المنتج</label>
              </div>
              <div className='field-container'>
                <input type='date' name='purchaseDate' id='purchaseDate' 
                className={data.purchaseDate === '' ? 'field' : 'active field'} 
                value={data.purchaseDate} 
                onChange={e => setData({...data, purchaseDate:e.target.value})} 
                />
                <label className={data.purchaseDate === '' ? '' : "active"}>تاريخ الشراء</label>
              </div>
              <div className='field-container'>
                <input type='date' name='purchaseDate' id='purchaseDate' 
                className={data.expire === '' ? 'field' : 'active field'} 
                value={data.expire} 
                onChange={e => setData({...data, expire:e.target.value})} 
                />
                <label className={data.expire === '' ? '' : "active"}>تاريخ الأنتهاء</label>
              </div>
              <div className={data.preparerName === '' ? 'field-container dropdown-container' : 'field-container dropdown-container active'}>
                <div className='preparer'  onClick={() => {
                  openDropdown('preparers')
                }}
              >{data.preparerName || "المجهز"}</div>
                <div  className={dropdowns.preparers ? 'dropdown active' : 'dropdown'}>
                  <ul>
                    {
                      preparers.map((item, index) => {
                        return (
                          <li onClick={() => {
                            setData({...data, preparerName:item.name, preparer:item._id})
                            openDropdown('')
                            }} key={index}>{item.name}</li>
                        )
                      })
                    }
                  </ul>
                </div>
              </div>
            </div>
            <div className='row cosmeticsPricesRow'>
              <div className='field-container'>
                <input type='number' min='0' step='1'  name='quantity' id='quantity' 
                    className={data.quantity === 0 ? 'field'  : 'active field' }
                    value={data.quantity} 
                    onChange={e => setData({...data, quantity:e.target.value})}  />
                <label className={data.quantity === '' ? '' : "active"}>الكمية</label>
              </div>
              <div className='field-container'>
                <input type='number' min='0' step='1000' name='purchasingPrice' id='purchasingPrice' 
                    className={data.purchasingPrice === 0 ? 'field'  : 'active field' }
                    value={data.purchasingPrice} 
                    onChange={e => setData({...data, purchasingPrice:e.target.value})}  />
                <label className={data.purchasingPrice === '' ? '' : "active"}>سعر الشراء</label>
              </div>
              <div className='field-container'>
                <input type='number' min='0' step='1000' name='sellingPrice' id='sellingPrice' 
                    className={data.sellingPrice === 0 ? 'field'  : 'active field' }
                    value={data.sellingPrice} 
                    onChange={e => setData({...data, sellingPrice:e.target.value})}  />
                <label className={data.sellingPrice === '' ? '' : "active"}>سعر البيع</label>
              </div>
            </div>
            <div className='row' style={{display:'grid', gridTemplateColumns:"repeat(2, 1fr)", gap:".5rem"}}>
              <div className='field-container'>
                <input type='number' min='0' step='1000' name='sellingPrice' id='sellingPrice' 
                    className={data.debt === 0 ? 'field'  : 'active field' }
                    value={data.debt} 
                    onChange={e => setData({...data, debt:e.target.value})}  />
                <label className={data.debt === '' ? '' : "active"}>المبلغ الكلي</label>
              </div>
              <div className={data.debt === '' ? 'field-container dropdown-container' : 'field-container dropdown-container active'} style={data.debt === 0 ? {opacity:0.5, pointerEvents:"none"} : {}}>
                <div className='debts'  onClick={() => {
                  openDropdown('debts')
                }}
              >{data.debtType || "نوع الشراء"}</div>
                <div  className={dropdowns.debts ? 'dropdown active' : 'dropdown'}>
                  <ul>
                    {
                      debts.map((item, index) => {
                        return (
                          <li onClick={() => {
                            setData({...data, debtType:item})
                            openDropdown('')
                            }} key={index}>{item}</li>
                        )
                      })
                    }
                  </ul>
                </div>
              </div>
            </div>
            <div className='row'>
              <button onClick={e => {
                e.preventDefault()
                addProduct(data)
              }}>
                <LoadingIcon active={isLoading} />
                <div>
                أضافة
                </div>
              </button>
            </div>
          </form>
          <p style={{marginTop:'1rem', color:'var(--primary)', fontSize:"var(--paragraph)", textAlign:'center'}}>{msg}</p>
        </section>
      </div>
    </main>
  )
}

export default AddCosmeticsMaterials