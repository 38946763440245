import './App.css';
import { lazy, Suspense } from 'react';
import Layout from './layouts/Layout';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Employee from './Pages/Employee';
import Sessions from './Pages/Sessions';
import CosmeticsMaterials from './Pages/CosmeticsMaterials';
import StorageMaterials from './Pages/StorageMaterials';
import { SkeletonTheme } from 'react-loading-skeleton';
import Debts from './Pages/Debts';
import { useEffect } from 'react';
import { url } from './URL';
import axios from 'axios'
import Loading from './Components/Loading';
import Cart from './Components/Cart';
import ChoosePatient from './Components/ChoosePatient';
import ChooseProduct from './Components/ChooseProducts';



const Patient = lazy(() => import('./Pages/Patient'));
const Receipt = lazy(() => import('./Pages/Receipt'));
const Patients = lazy(() => import('./Pages/Patients'));
const EmployeesReceipts = lazy(() => import('./Pages/EmployeesReceipts'));
const CancelledSessions = lazy(() => import('./Pages/CancelledSessions'));
const AddPatients = lazy(() => import('./Pages/AddPatients'));
const AddStaff = lazy(() => import('./Pages/AddStaff'));
const Birthdays = lazy(() => import('./Pages/Birthdays'));
const ContactPatients = lazy(() => import('./Pages/ContactPatients'));
const Staff = lazy(() => import('./Pages/Staff'));
const Revenues = lazy(() => import('./Pages/Revenues'));
const Salaries = lazy(() => import('./Pages/Salaries'));
const AddServices = lazy(() => import('./Pages/AddServices'));
const ProductsExpenses = lazy(() => import('./Pages/ProductsExpenses'));
const AddCategory = lazy(() => import('./Pages/AddCategory'));
const SystemInfo = lazy(() => import('./Pages/SystemInfo'));
const Log = lazy(() => import('./Pages/Log'));
const Login = lazy(() => import('./Pages/Login'));
const Receipts = lazy(() => import('./Pages/Receipts'));
const EmployeeReceipt = lazy(() => import('./Pages/EmployeeReceipt'));
const Preparers = lazy(() => import('./Pages/Preparers'))
const Purchases = lazy(() => import('./Pages/Purchases'))
const FinancialFund = lazy(() => import('./Pages/FinancialFund'))
const AddFinancialFund = lazy(() => import('./Pages/AddFinancialFund'))
const ProductsDebts = lazy(() => import('./Pages/ProductsDebts'))
const MostPaid = lazy(() => import('./Pages/MostPaid'))
const Devices = lazy(() => import('./Pages/Devices'))
const Settings = lazy(() => import('./Pages/Settings'))
const CartInfo = lazy(() => import('./Pages/CartInfo'))
const Visits = lazy(() => import('./Pages/Visits'))

function App() {
  const location = window.location.pathname
  useEffect(() => {
    axios.get(`${url}/check-auth`, {withCredentials:true})
        .then(res => {
          if(res.status === 400 || res.status === 401){
            // window.location = '/login'
          }
        })
        .catch(err => {
          if(err){
            if((err.response.status === 400 || err.response.status === 401) && location !== '/login'){
              window.location = '/login'
              // console.log(location)
            }
          }
        })
    // axios.get(`${url}/remove-cookies`, {withCredentials:true})
    //       .then(res => console.log(res))
  })
  return (
    <Router>
      <SkeletonTheme baseColor="#c9c9c9" highlightColor="#e8e8e8">
        <div className="App">
          <Suspense fallback={<Loading />}>
            <Routes>
              <Route path='/login' element={<Login />} />
              <Route element={<LayoutWrapper />}>
                <Route path='/patients/add' element={<AddPatients />} />
                <Route path='/patients/' element={<Patients />} />
                <Route path='/patients/:id' element={<Patient />} />
                <Route path='/patients/birthdays' element={<Birthdays />} />
                <Route path='/patients/most-paid' element={<MostPaid />} />
                <Route path='/patients/contact' element={<ContactPatients />} />
                <Route path='/patients/debt' element={<Debts />} />
                <Route path='/patients/visits' element={<Visits />} />
                <Route path='/employees/add' element={<AddStaff />} />
                <Route path='/employees/' element={<Staff />} />
                <Route path='/employees/:id' element={<Employee />} />
                <Route path='/employees/receipts' element={<EmployeesReceipts />} />
                <Route path='/employees/receipts/:id' element={<EmployeeReceipt />} />
                <Route path='/expenses/revenues' element={<Revenues />} />
                <Route path='/expenses/salaries' element={<Salaries />} />
                <Route path='/expenses/products' element={<ProductsExpenses />} />
                <Route path='/expenses/financial-fund' element={<FinancialFund />} />
                <Route path='/expenses/add-financial-fund' element={<AddFinancialFund />} />
                <Route path='/materials/cosmetics' element={<CosmeticsMaterials />} />
                <Route path='/materials/storage' element={<StorageMaterials />} />
                <Route path='/materials/purchases' element={<Purchases />} />
                <Route path='/materials/debts' element={<ProductsDebts />} />
                <Route path='/preparers' element={<Preparers />} />
                <Route path='/sessions' element={<Sessions />} />
                <Route path='/materials/sell/choose-patient' element={<ChoosePatient />} />
                <Route path='/materials/sell/add-products' element={<ChooseProduct />} />
                <Route path='/materials/sell/checkout' element={<Cart />} />
                <Route path='/services/add-service' element={<AddServices />} />
                <Route path='/services/add-category' element={<AddCategory />} />
                <Route path='/receipts/' element={<Receipts />} />
                <Route path='/receipts/:id' element={<Receipt />} />
                <Route path='/system/logs' element={<Log />} /> 
                <Route path='/system/devices' element={<Devices />} /> 
                <Route path='/system/info' element={<SystemInfo />} />
                <Route path='/system/cancelled-sessions' element={<CancelledSessions />} />
                <Route path='/system/settings' element={<Settings />} />
                <Route path='/carts/:id' element={<CartInfo />} />
              </Route>
            </Routes>
          </Suspense>
        </div>
      </SkeletonTheme>
    </Router>
  );
}

// Layout wrapper component to wrap routes that need the Layout
const LayoutWrapper = () => (
  <Layout>
    <Routes>
      {/* All routes that need to be wrapped by Layout */}
      <Route path='/patients/add' element={<AddPatients />} />
      <Route path='/patients/' element={<Patients />} />
      <Route path='/patients/:id' element={<Patient />} />
      <Route path='/patients/birthdays' element={<Birthdays />} />
      <Route path='/patients/most-paid' element={<MostPaid />} />
      <Route path='/patients/contact' element={<ContactPatients />} />
      <Route path='/patients/debt' element={<Debts />} />
      <Route path='/patients/visits' element={<Visits />} />
      <Route path='/employees/add' element={<AddStaff />} />
      <Route path='/employees/receipts' element={<EmployeesReceipts />} />
      <Route path='/employees/receipts/:id' element={<EmployeeReceipt />} />
      <Route path='/employees/' element={<Staff />} />
      <Route path='/employees/:id' element={<Employee />} />
      <Route path='/expenses/revenues' element={<Revenues />} />
      <Route path='/expenses/salaries' element={<Salaries />} />
      <Route path='/expenses/products' element={<ProductsExpenses />} />
      <Route path='/materials/cosmetics' element={<CosmeticsMaterials />} />
      <Route path='/materials/storage' element={<StorageMaterials />} />
      <Route path='/materials/sell/choose-patient' element={<ChoosePatient />} />
      <Route path='/materials/sell/add-products' element={<ChooseProduct />} />
      <Route path='/materials/sell/checkout' element={<Cart />} />
      <Route path='/materials/purchases' element={<Purchases />} />
      <Route path='/materials/debts' element={<ProductsDebts />} />
      <Route path='/expenses/financial-fund' element={<FinancialFund />} />
      <Route path='/expenses/add-financial-fund' element={<AddFinancialFund />} />
      <Route path='/preparers' element={<Preparers />} />
      <Route path='/sessions' element={<Sessions />} />
      <Route path='/services/add-service' element={<AddServices />} />
      <Route path='/services/add-category' element={<AddCategory />} />
      <Route path='/receipts/' element={<Receipts />} />
      <Route path='/receipts/:id' element={<Receipt />} />
      <Route path='/system/logs' element={<Log />} />
      <Route path='/system/devices' element={<Devices />} /> 
      <Route path='/system/info' element={<SystemInfo />} />
      <Route path='/system/cancelled-sessions' element={<CancelledSessions />} />
      <Route path='/system/settings' element={<Settings />} />
      <Route path='/carts/:id' element={<CartInfo />} />
    </Routes>
  </Layout>
);

export default App;
