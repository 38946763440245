import React from 'react';
import '../styles/ReceiptPaper.css'; // Assuming you extract the CSS to an external file
import { BsFacebook, BsGeo, BsInstagram, BsTelephone, BsWhatsapp } from 'react-icons/bs';

const ReceiptPaper = ({ sessionData }) => {
    return (
        <div className="receipt-paper" id='receiptsPaper'>
            <div className="reciepe-receipt-paper">
                <div className="logo">
                    <img src={require('../Assets/Images/logo.png')} loading="lazy" alt="logo" />
                </div>
                <div className="dates">
                    <p>{`${new Date().getFullYear()}/${new Date().getMonth() + 1}/${new Date().getDate()}`}</p>
                    <p className="hour">{`${new Date().getHours() > 12 ? new Date().getHours() - 12 : new Date().getHours()}:${new Date().getMinutes()} ${new Date().getHours() >= 12 ? 'م' : "ص"}`}</p>
                </div>
            </div>
            <div className="content">
                <ul>
                    <li>
                        <div className="label">:الأسم</div>
                        <div className="text">{sessionData.patient}</div>
                    </li>
                    <li>
                        <div className="label">:رقم الهاتف</div>
                        <div className="text">{sessionData.number}</div>
                    </li>
                    <li>
                        <div className="label">:المبلغ المدفوع</div>
                        <div className="text">د.ع {sessionData.paid}</div>
                    </li>
                    <li>
                        <div className="label">:وذلك عن</div>
                        <div className="text">{sessionData.service}</div>
                    </li>
                    <li>
                        <div className="label">:الرسبشن</div>
                        <div className="text">{sessionData.responsible}</div>
                    </li>
                    <li>
                        <div className="label">:ملاحظات</div>
                        <div className="text">{sessionData.notes}</div>
                    </li>
                </ul>
            </div>
            <div className="signatures">
                <div>
                    <p>:توقيع الزبون</p>
                </div>
                <div>
                    <p>:توقيع المحاسب</p>
                </div>
            </div>
            <div className="notes">
                <ul>
                    <li>لا يحق للزبون استرجاع المبلغ المدفوع بعد قطع الوصل</li>
                    <li>لا يحق للزبون استخدام الوصل بعد مرور 4 أشهر من تاريخ قطع الوصل</li>
                </ul>
            </div>
            <div className="contacts">
                <div className="row">
                    <div className="socials">
                        <div className="icons">
                            <div>
                                {<BsInstagram />}
                            </div>
                            <div>
                                {<BsFacebook />}
                            </div>
                            <div>
                                {<BsWhatsapp />}
                            </div>
                        </div>
                        <div>
                            <p>@Pelinclinic</p>
                        </div>
                    </div>
                    <div className="location">
                        <div className="icons">
                            <div>
                                {<BsGeo />}
                            </div>
                        </div>
                        <div>
                            <p>البصرة, العباسية, شارع سيد حامد</p>
                        </div>
                    </div>
                </div>
                <div className="phone">
                    <div>
                        <BsTelephone />
                    </div>
                    <div>0780 088 0051 - 0772 910 7070</div>
                </div>
            </div>
        </div>
    );
};

export default ReceiptPaper;
